<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" class="filter-from" label-width="70px" ref="logFilterForm"
      size="medium">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="兑换状态：" prop="status">
          <el-select placeholder="请选择" v-model="form.status">
            <el-option :key="item.value" :label="item.name" :value="item.value" v-for="item in [{name: '全部', value: -1},{name: '未兑换', value: 0}, {name: '已兑换', value: 1}, {name: '失效', value: 2}]">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="兑换方式：" prop="exchange_type">
          <el-select placeholder="请选择" v-model="form.exchange_type">
            <el-option :key="item.value" :label="item.name" :value="item.value" v-for="item in [{name: '全部', value: -1},{name: '自主兑换', value: 1}, {name: '其他', value: 0}]">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!--
         <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
      -->
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
// import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  name: "code-record-list-filter",
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryOptions: [],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {

  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    // DatePeriodPicker,
  },
};
</script>
