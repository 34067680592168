<template>
  <div class="page">
    <list-layout
      :filter-form="filterForm"
      :on-fetch="getList"
      :thead="thead"
      @command="onCommand"
    >
      <template #item_custom_numbers="{ row }">
        {{ row.total_count + '/' + row.exchange_count }}
      </template>
    </list-layout>
    <el-dialog :visible.sync="isShowExchangeDetails" class="dialog-vertical" close-on-click-modal close-on-press-escape title="兑换情况" width="900px">
      <list-layout
        :filter-form="detailForm"
        :on-fetch="onFetchDetail"
        :thead="detailHead"
        ref="detailForm"
        v-if="isShowExchangeDetails"
      >
        <template #filter>
          <code-record-list-filter :filter-form="detailForm" :upload-filter="ok"></code-record-list-filter>
        </template>
        <template #item_status_text="{row}">
          <list-status-text :text="row.status_text" :type="row.status === 2 ? 'info' : row.status === 1 ? 'danger' : 'success'"></list-status-text>
        </template>
      </list-layout>
    </el-dialog>
  </div>
</template>

<script>
import ListLayout from '../../../base/layout/ListLayout'
import {
  exportCollegeCodeIndex,
  exportCollegeCodeRecordIndex,
  getCollegeCodeIndex,
  getExchangeDetail,
} from '../api/code-manage'
import ListStatusText from '../../../base/components/List/ListStatusText'
import CodeRecordListFilter from '../components/CodeRecordListFilter'
export default {
  components: {CodeRecordListFilter, ListStatusText, ListLayout },
  data() {
    return {
      filterForm: {
        page: 1,
        page_size: 15,
        teacher: '',
        status: -1,
        order_by: 'id',
        is_desc: 1,
        export_ids: [],
        source_type: -1,
        start_time: -1,
        end_time: -1,
      },
      thead: [
        { label: '课程来源', prop: 'source_text', minWidth: 120 },
        { label: '教师', prop: 'teacher', minWidth: 100 },
        // { label: '包含课程数', prop: 'total_count', minWidth: 100 },
        { label: '兑换码有效期', prop: 'expire_time_text', minWidth: 200 },
        {
          label: '兑换码数量/已兑换',
          prop: 'custom_numbers',
          type: 'slot',
          minWidth: 200,
        },
        { label: '发放时间', prop: 'create_time', minWidth: 200 },
        // { label: '发放人', prop: '', minWidth: 100 },
        { label: '状态', prop: 'status_text', minWidth: 100 },
        {
          label: '操作',
          type: 'operation',
          width: 200,
          operations: [
            {
              text: '当前兑换情况',
              command: 'details',
            },
            {
              text: '导出兑换码',
              command: 'export',
              // disabled: (row) => row.exchange_count === 0,
            },
          ],
          minWidth: 120,
          fixed: 'right',
        },
      ],
      isShowExchangeDetails: false,
      detailForm: {
        page: 1,
        page_size: 15,
        course_code_id: '',
        status: -1,
        exchange_type: -1,
        order_by: 'exchange_time',
        is_desc: 1,
        export_ids: [],
        start_time: -1,
        end_time: -1,
      },
      /**
       * "status": 1,//0：未兑换，1：已兑换，2：失效
       "exchange_time": "2024-10-18 16:24:32",
       "exchange_text": "自主兑换",
       "status_text": "已兑换",
       */
      detailHead: [
        {
          label: '兑换码',
          prop: 'code_number',
          minWidth: 120,
        },
        {
          label: '兑换状态',
          prop: 'status_text',
          minWidth: 120,
          type: 'slot'
        },
        {
          label: '兑换人',
          prop: 'user_name',
          minWidth: 120,
        },
        {
          label: '联系方式',
          prop: 'phone',
          minWidth: 120,
        },
        {
          label: '兑换时间',
          prop: 'exchange_time',
          minWidth: 180,
        },
        {
          label: '兑换方式',
          prop: 'exchange_text',
          minWidth: 120,
        },
      ],
    }
  },
  methods: {
    // 筛选回调
    ok(e) {
      const pageData = { ...this.detailForm, ...e, page: 1 };
      this.$refs.detailForm.getList(pageData)
    },
    onFetchDetail(q) {
      return getExchangeDetail(q)
    },
    onCommand({ row, command }) {
      switch (command) {
        case 'export':
          this.doExport({
            course_code_id: row.id,
          })
          break
        case 'details':
          this.detailForm.course_code_id = row.id
          this.isShowExchangeDetails = true
          break
        case 'delete':
        default:
      }
    },
    doExport(extraData) {
      return exportCollegeCodeRecordIndex({
        ...{
          course_code_id: '',
          status: -1,
          exchange_type: -1,
          order_by: 'exchange_time',
          is_desc: 1,
          export_ids: [],
          start_time: -1,
          end_time: -1,
        },
        ...extraData,
      })
    },
    getList(query) {
      return getCollegeCodeIndex(query)
    },
  },
}
</script>

<style lang="scss" scoped></style>
