import api from '@/base/utils/request'
import fetchFile from '@/base/utils/download'

export const getCollegeCodeIndex = (data) => {
  return api({ url: '/admin/college/courseCode/index', method: 'post', data })
}

export const exportCollegeCodeIndex = (data) => {
  return fetchFile({
    url: '/admin/college/courseCode/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

export const exportCollegeCodeRecordIndex = (data) => {
  return fetchFile({
    url: '/admin/college/courseCodeRecord/exportIndex',
    method: 'post',
    data,
    download: true,
  })
}

export const getExchangeDetail = data => {
  return api({
    url: '/admin/college/courseCodeRecord/index',
    method: 'post',
    data,
  })
}
